import Axios from '@/axios';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  retrieveReport: async (reportName, params) => {
    const response = await Axios.get(`/report-service/v2/reports/${reportName}`, {
      params
    });

    return response.data.data;
  },
  retrieveEventSalesReport: async (eventId, params, contentType) => {
    const useV2Endpoint = await unleashFeatureEnabled(UnleashKeys.Mongo);

    if (!contentType) contentType = 'application/json';

    if (useV2Endpoint) {
      const response = await Axios.get(`/report-service/v2/reports/event-sales-v2/v2/${eventId}`, {
        params,
        data: {},
        headers: {
          'Content-Type': contentType
        }
      });

      if (contentType === 'text/csv') {
        return response.data;
      }

      return response.data.data;
    } else {
      const response = await Axios.get(`/report-service/v2/reports/event-sales/v2/${eventId}`, {
        params,
        data: {},
        headers: {
          'Content-Type': contentType
        }
      });

      if (contentType === 'text/csv') {
        return response.data;
      }

      return response.data.data;
    }
  },
  listRaffleSales: async (params, contentType) => {
    const useV2Endpoint = await unleashFeatureEnabled(UnleashKeys.Mongo);

    if (!contentType) contentType = 'application/json';

    if (useV2Endpoint) {
      const response = await Axios.get(`report-service/v2/reports/event-sales-v2/v2`, {
        data: {},
        params,
        headers: {
          'Content-Type': contentType
        }
      });

      if (contentType === 'text/csv') {
        return response.data;
      }

      return response.data.data;
    } else {
      if (!contentType) contentType = 'application/json';

      const response = await Axios.get(`report-service/v2/reports/event-sales/v2`, {
        data: {},
        params,
        headers: {
          'Content-Type': contentType
        }
      });

      if (contentType === 'text/csv') {
        return response.data;
      }

      return response.data.data;
    }
  },
  inSupportOfSales: async (eventId, params, contentType) => {
    if (!contentType) contentType = 'application/json';

    const response = await Axios.get(`/report-service/v2/reports/event-member-sales/${eventId}`, {
      params,
      data: {},
      headers: {
        'Content-Type': contentType
      }
    });

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },
  goldrushSales: async (params) => {
    const response = await Axios.get(`/report-service/v2/reports/goldrush-sales`, {
      params
    });

    return response.data.data;
  },
  goldrushStationSalesReport: async (eventId, params, contentType) => {
    const useV2Endpoint = await unleashFeatureEnabled(UnleashKeys.Mongo);
    if (!contentType) contentType = 'application/json';

    if (useV2Endpoint) {
      const response = await Axios.get(`/report-service/v2/reports/goldrush-station-sales/v2/${eventId}`, {
        params,
        data: {},
        headers: {
          'Content-Type': contentType
        }
      });

      if (contentType === 'text/csv') {
        return response.data;
      }

      return response.data.data;
    }

    const response = await Axios.get(`/report-service/v2/reports/goldrush-station-sales/${eventId}`, {
      params,
      data: {},
      headers: {
        'Content-Type': contentType
      }
    });

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },
  retrieveDeviceSalesReport: async (eventId, params, contentType) => {
    const useV2Endpoint = await unleashFeatureEnabled(UnleashKeys.Mongo);

    if (!contentType) contentType = 'application/json';
    let response;

    if (useV2Endpoint) {
      response = await Axios.get(`/report-service/v2/reports/device-sales/v2/${eventId}`, {
        params,
        data: {},
        headers: {
          'Content-Type': contentType
        }
      });
    } else {
      response = await Axios.get(`/report-service/v2/reports/device-sales/${eventId}`, {
        params,
        data: {},
        headers: {
          'Content-Type': contentType
        }
      });
    }

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },

  retrieveTopSellersReport: async (eventId, params, contentType) => {
    const useV2Endpoint = await unleashFeatureEnabled(UnleashKeys.Mongo);

    if (!contentType) contentType = 'application/json';
    let response;

    if (useV2Endpoint) {
      response = await Axios.get(`/report-service/v2/reports/top-sellers/v2/${eventId}`, {
        params,
        data: {},
        headers: {
          'Content-Type': contentType
        }
      });
    } else {
      response = await Axios.get(`/report-service/v2/reports/top-sellers/${eventId}`, {
        params,
        data: {}
      });
    }

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },
  retrieveTicketSalesReport: async (eventId, params, contentType) => {
    if (!contentType) contentType = 'application/json';
    const response = await Axios.get(`/report-service/v2/reports/ticket-package-sales/${eventId}`, {
      params,
      data: {},
      headers: {
        'Content-Type': contentType
      }
    });
    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },
  retrieveTicketSalesReportV2: async ({ eventId, contentType }) => {
    if (!eventId) throw new Error('Event ID is required');
    const body = {
      filters: [{ field: 'eventId', operator: '=', value: eventId }]
    };

    const response = await Axios.post('/report-service/v2/reports/ticket_package_sales_report', body, {
      headers: {
        Accept: contentType
      }
    });

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data.data;
  },
  cashDropSummaryReport: async (params, contentType) => {
    if (!contentType) contentType = 'application/json';
    const response = await Axios.get(`/report-service/v2/reports/device-cash-drop-summary/`, {
      params,
      data: {},
      headers: {
        'Content-Type': contentType
      }
    });
    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },

  retrieveSalesBySellerReport: async ({ eventId }) => {
    if (!eventId) throw new Error('Event ID is required');

    // TODO: This will need updating when we finalize these sales reports.
    // This should be querying EventId and it be a uuid, but the view is not that
    const body = {
      filters: [{ field: 'eventId', operator: '=', value: eventId }]
    };

    const response = await Axios.post('/report-service/v2/reports/ticket-seller-sales-by-event', body);

    return response.data.data.data;
  },
  retrieveEventMemberSalesReport: async ({
    eventId,
    page,
    pageSize,
    sortBy,
    sortDir,
    organizationId,
    search,
    active,
    contentType
  }) => {
    if (!eventId) throw new Error('Event ID is required');

    const filters = [
      {
        field: 'eventId',
        operator: '=',
        value: eventId
      },
      {
        field: ['eventMemberName', 'eventMemberNumber', 'email'],
        operator: '%',
        value: search
      }
    ];

    if (active) {
      filters.push({
        field: 'active',
        operator: '=',
        value: active
      });
    }

    const body = {
      filters,
      page,
      pageSize,
      sortBy,
      sortDir,
      organizationId
    };

    const response = await Axios.post('/report-service/v2/reports/event-members-sales-report', body, {
      headers: {
        Accept: contentType
      }
    });

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },

  retrieveDashboardSalesReports: async ({ orgId, contentType = 'application/json' }) => {
    const body = {
      organizationId: orgId
    };
    const headers = {
      Accept: contentType
    };
    const response = await Axios.post(`/report-service/v2/reports/dashboard-kpi`, body, { headers });

    if (contentType === 'text/csv') {
      return response.data;
    }
    return response.data.data.data[0];
  },
  retrieveDashboardVoidedSalesReports: async ({ eventId, contentType, page, pageSize, sortBy, sortDir }) => {
    const body = {
      filters: [{ field: 'eventId', operator: '=', value: eventId }],
      page,
      pageSize,
      sortBy,
      sortDir
    };

    const headers = {
      Accept: contentType
    };

    const response = await Axios.post(`/report-service/v2/reports/voided-orders-report`, body, { headers });

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  },
  retrieveTotalSalesReport: async ({ orgId, contentType = 'application/json' }) => {
    const body = {
      organizationId: orgId
    };

    const headers = {
      Accept: contentType
    };
    const response = await Axios.post(`/report-service/v2/reports/total-sales-last-30-days`, body, { headers });

    if (contentType === 'text/csv') {
      return response.data;
    }

    return response.data.data;
  }
};
